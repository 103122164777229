@import './variables.css';

html,body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: var(--backgroundLight);
}

div {
    text-align:center;
}

header { /*This is the bar that appears at the top of the game.*/
    background-color: #E05B2B; /*var(--headerFill);*/
    min-height: 6vh; /*This means 8% of the viewport height.*/
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(1vh);
    font-size: calc(12px + 4vmin); /*This means 10px plus 2% of the window's smallest dimension.*/
    font-family: var(--headerFont);
    color: var(--headerTextColor);
}

h1 { /*Big sweeping labels/headers*/
    font-size: calc(12px + 3.5vmin); /*This means 10px plus 2% of the window's smallest dimension.*/
    font-family: var(--headerFont);
    color: var(--headerTextColor);
    font-weight: normal;
    font-style: italic;
}

h2 { /*Large labels.*/
    font-size: calc(12px + 2vmin); /*This means 10px plus 2% of the window's smallest dimension.*/
    font-family: var(--headerFont);
    color: var(--headerTextColor);
    font-weight: normal;
    font-style: normal;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

h5 { /*Slightly larger than normal text.*/
    font-family: var(--textFont);
    font-weight: normal;
    font-size: calc(8px + 2vmin);
    color: var(--textColorOnDark);
}

h6 { /*Notification and/or error text.*/
    font-family: var(--textFont);
    font-weight: 700;
    font-size: calc(4px + 2vmin);
    color: var(--textColorHighlight);
    margin: 3px;
}

#errormessage {
    font-family: var(--textFont);
    font-weight: 700;
    font-size: calc(12px + 1vmin);
    color: var(--textColorHighlight);
    margin: 3px;
}

p {
    font-family: var(--textFont);
    font-weight: 700;
    font-size: calc(12px + 1vmin);
    min-font-size: 12px;
    color: var(--textColorOnDark);
}

a {
    font-family: var(--textFont);
    font-size: calc(12px + 1vmin);
    color: var(--buttonHoverFill);
}

.highlight {
    color: var(--textColorHighlight);
}

.highlight-blue {
    color: var(--textColorLiberal);
}

/* Credit to https://designtheway.com/css-buttons/ for the CSS source code.*/
button {
    background:transparent;
    border: 2px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
    font-size: calc(6px + 4vmin);
    font-family: var(--headerFont);
    color: var(--headerTextColor);
    background: var(--buttonFill);
    line-height: 1;
    margin: 18px;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display:inline-block;
    outline:none;
    position:relative;
    border-radius: 10px;
    top:0;
    -webkit-transition: all 0.15s ease-in-out 0s, visibility 0s;
    -moz-transition: all 0.15s ease-in-out 0s, visibility 0s;
    transition: all 0.15s ease-in-out 0s, visibility 0s;
    box-shadow: 0 5px 0 var(--buttonShadowFill);
    -webkit-tap-highlight-color: transparent;
}

button:hover{
    background: var(--buttonHoverFill);
}

button:active{
    top:5px;
    background: var(--buttonFill);
    box-shadow: 0 0 0 var(--buttonShadowFill);
}

button:disabled{
    box-shadow: 0 5px 0 var(--buttonDisabledShadowFill);
    background: var(--buttonDisabledFill);
    cursor: not-allowed;
}

/* Override for pulsating buttons (for the end term button)*/
#pulse-button {

}

#pulse-button:disabled {

}

input {
    font-family: var(--textFont);
    width: 40vmin;
    min-width: 20px;
    font-size: calc(4px + 3vmin);
    color: var(--textColorOnWhite);
    border-radius: 5px;
    padding: 5px;
    border: 2px solid transparent;
    outline:none;
}

input::placeholder {
    color: var(--textColorOnDark);
}

textarea {
    font-family: var(--textFont);
    width: 80vmin;
    height: 5vmin;
    min-height: 10px;
    resize: none;
    font-size: calc(2px + 2vmin);
    user-modify: false;
    color: var(--textColorOnWhite);
    border-radius: 5px;
    padding: calc(5px + 1.5vmin) 5px 5px;
    border: 2px solid transparent;
    outline:none;
}

/* Below snackbar CSS was provided by W3Schools (https://www.w3schools.com/howto/howto_js_snackbar.asp)*/
/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 30vw; /* Set a default minimum width */
    background-color: var(--alertFill);
    display:inline-block;
    color: var(--headerTextColor);
    font-family: var(--textFont);
    font-size: calc(2px + 2vmin);
    border-radius: 10px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 100; /* Add a z-index if needed */
    bottom: 30px; /* 30px from the bottom */
    transform: translateX(-50%);
}

/* Show the snackbar*/
#snackbar.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0; visibility: visible;}
    to {bottom: 30px; opacity: 1; visibility: visible;}
}

@keyframes fadein {
    from {bottom: 0; opacity: 0; visibility: visible;}
    to {bottom: 30px; opacity: 1; visibility: visible}
}

@-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1; visibility: visible}
    to {bottom: 0; opacity: 0; visibility: hidden}
}

@keyframes fadeout {
    from {bottom: 30px; opacity: 1; visibility: visible}
    to {bottom: 0; opacity: 0; visibility: hidden}
}

.removeTile {
    transition-timing-function: ease-in-out;
    -webkit-animation: slideOut 0.5s;
    animation: slideOut 0.5s;
}

@-webkit-keyframes slideOut {
    0% {left:0; opacity: 1;}
    100% {left: -100%; opacity: 0; visibility: hidden}
}

@keyframes slideOut {
    0% {left:0; opacity: 1;}
    100% {left: -100%; opacity: 0; visibility: hidden}
}


#victory-header {
    width: 100%;
}