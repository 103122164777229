@import '../variables.css';

#role {
    width: 25vmin;
    height: auto;
    display: inline;
    float:left;
    align-self: center;
    margin: 10px 2vmin 10px 10px;
}

