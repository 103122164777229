@import '../variables.css';

#icon-container {
    width: 100%;
}

#icon {
    display: inline;
    width: 18vmin;
    border-radius: 8%/8%;
    margin: 3px 3px;
    padding: 5px 5px;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none; /* From IE10 only */
    user-select: none; /* Not valid CSS yet, as of July 2012 */
    -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
}

#icon.disabled {
    filter: brightness(calc(var(--playerBrightnessFilter) + 20%));
    pointer-events: none;
}

#icon-text {
    min-font-size: 12;
    font-size: calc(12px + 0.5vmin);
    margin-right: 5px;
}

#locked-icon-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#locked-icon-button {
    background: var(--buttonAltFill);
    box-shadow: 0 5px 0 var(--buttonAltShadowFill);
    align-items: center;
    height: 100%;
}

#locked-icon-button:hover{
    background: var(--buttonAltHoverFill);
}

#locked-icon-button:active{
    top:5px;
    background: var(--buttonAltFill);
    box-shadow: 0 0 0 var(--buttonAltShadowFill);
}

#locked-icon-button:disabled{
    box-shadow: 0 5px 0 var(--buttonDisabledShadowFill);
    background: var(--buttonDisabledFill);
    cursor: not-allowed;
}

#twitter-icon {
    height: calc(30px + 0.6vmin);
}