
.announcement-container {
  --badge-size: 16vmin;
  background-color: rgb(34, 34, 34);
  margin: calc(var(--badge-size) / 2 + 10px) auto 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: fit-content;
  width: 50vmin;

  line-height: 1.5;
  padding: 20px;
}

.announcement-container > * > p {
  font-style: normal;
  font-weight: 400;
  text-align: center;

}

.announcement-image-container {
  width: var(--badge-size);
  height: var(--badge-size);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

.announcement-image-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px 0 #7d4c09ff;
  padding: 0;
  margin: 5px 0;
  font-size: calc(12px + 4vmin);
}

.announcement-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}