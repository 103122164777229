:root {
    --headerFill:#e05b2b;
    --headerTextColor: #ffffff;
    --headerFont: Germania One, cursive;
    --backgroundLight: #4d4945;
    --backgroundDark: #372e25;
    --textFont: Montserrat, sans-serif;
    --textColorOnWhite: #69645e;
    --textColorOnDark: #bbbbbb;
    --textColorHighlight: var(--headerFill);
    --textColorLiberal: #5a9ba6;
    --textColorWhite: #ffffff;
    --textShadowed: #141414;
    --buttonFill: var(--headerFill);
    --buttonShadowFill: #b4431a;
    --buttonHoverFill: #e67a53;
    --buttonDisabledFill: #928079;
    --buttonDisabledShadowFill: #766058;

    --buttonAltFill: var(--textColorLiberal);
    --buttonAltHoverFill: #7abcca;
    --buttonAltShadowFill: #386F8B;

    --alertFill: #141414;
    --alertOverlayOpacity: 0.8;

    --playerBrightnessFilter: 40%;
    --playerDisabledTextColor: #999999;

    --selectionHover: rgba(246, 181, 63, 1.00);
    --selectionPressed: rgba(205, 135, 9, 1.00);
    --selectionSelected: rgba(228, 105, 73, 1.00);
    --selectionNone: rgba(246, 181, 63, 0.00);

    --eventBarIn: 1s;
    --eventBarOut: 1s;
    --eventBarOpacity: 0.9;

    --statusBarColor: #141414;

    --etOffset: 17.6%;
    --etSpacing: 19.14%;
    --etDuration: 1.5s;

    --investigationWidth: 40vmin;

    --deckPolicyLeft: 16%;
}