@import '../variables.css';

#event-bar {
    width: 100%;
    top: 30%;
    position: fixed;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    visibility: hidden;
    z-index: 48;
    transition: visibility var(--eventBarIn);
}

#bar-background {
    width: 100%;
    min-height: 15vmin;
    background-color: var(--alertFill);
    z-index: 48;
    animation: ease-in-out;
}

#event-bar-text {
    position: absolute;
    z-index: 49;
    display: flex;
    font-style: italic;
    margin: 0 auto;
    animation: ease-out;
    opacity: 0;
}

#event-bar.start-eventbar {
    visibility: hidden;
}

#event-bar.appear-eventbar {
    visibility: visible;
    transition: visibility 0s;
}

#bar-background.appear-eventbar {
    visibility: visible;
    transition: 0s;
    animation: appear-bar var(--eventBarIn);
    opacity: var(--eventBarOpacity);
}

#event-bar-text.appear-eventbar {
    visibility: visible;
    transition: visibility 0s;
    animation: appear-text var(--eventBarIn);
    opacity: 1;
}

#event-bar.disappear-eventbar {
    visibility: hidden;
    transition: visibility var(--eventBarOut);
}

#bar-background.disappear-eventbar {
    visibility: hidden;
    transition: visibility var(--eventBarOut);
    animation: disappear-bar var(--eventBarOut);
}

#event-bar-text.disappear-eventbar {
    visibility: hidden;
    transition: visibility var(--eventBarOut);
    animation: disappear-text var(--eventBarOut);
}

@keyframes appear-text {
    0%{opacity: 0; padding-top: 20px}
    60%{opacity: 0; padding-top: 20px}
    100% {opacity: 1; padding-top: 0}
}

@keyframes appear-bar {
    0%{opacity: 0; width: 0}
    60% {opacity: var(--eventBarOpacity); width: 100%}
}

@keyframes disappear-text {
    0%  {opacity: 1; visibility: visible}
    40% {opacity: 0; visibility: hidden}
    60% {opacity: 0; visibility: hidden}
}

@keyframes disappear-bar {
    0%   {opacity: var(--eventBarOpacity); visibility: visible}
    60%  {opacity: var(--eventBarOpacity); visibility: visible}
    100% {opacity: 0; visibility: hidden}
}