@import '../variables.css';

#deck-container {
    width: 14vmin;
    height: fit-content;
}

#deck-base {
    width: 14vmin;
    height: auto;
}

#deck-card-count {
    font-size: 3.5vmin;
    position: absolute;
    color: var(--textColorWhite);
    left: 30%;
    top: 58%;
    text-align: center;
    width: 40%;
}

#deck-policy {
    position: absolute;
    width: 69%;
    opacity: 1;
}

#deck-policy.deck-final-show, #deck-policy.deck-transition-show {
    transition: 0.4s top ease-in, 0.2s opacity;
    opacity: 1;
    left: 16%;
}

#deck-policy.deck-final-hide, #deck-policy.deck-transition-hide {
    transition: 0.2s 0.3s opacity, 0.5s left ease-in;
    opacity: 0;
    left: -30%;
}