@import '../variables.css';

#alert {
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    visibility: hidden;
    z-index: 75;
}

#alert-background {
    position: fixed;
    width: 100%;
    height: 110%;
    min-height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 50;
    box-sizing: border-box;
    background-color: var(--alertFill);
    opacity:var(--alertOverlayOpacity);
}

#alert-box {
    position: absolute;
    z-index: 60;
    opacity: 1;
    min-width: 80vmin;
    max-width: 40vw;
    min-height: 60vmin;
    padding: 20px 30px;
    background-color: var(--alertFill);
    text-align: center;
    top: 10vh;
    margin-bottom: 10px;
}

.left-align {
    text-align: left;
}

/* DEFAULT APPEAR */

.appear-custom-alert{
    transition-timing-function: ease-in-out;
    -webkit-animation: appear-custom-alert 0.5s;
    animation: appear-custom-alert 0.5s;
    visibility: visible;
    transition: visibility 0.5s;
    opacity: 1;
}

.disappear-custom-alert {
    transition-timing-function: ease-in-out;
    -webkit-animation: disappear-custom-alert 1s;
    animation: disappear-custom-alert 1s;
    visibility: hidden;
    transition: visibility 0.5s;
}

@-webkit-keyframes appear-custom-alert {
    0% {opacity: 0;}
    40% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes appear-custom-alert {
    0% {opacity: 0;}
    40% {opacity: 0;}
    100% {opacity: 1;}
}

@-webkit-keyframes disappear-custom-alert {
    0% {opacity: 1;}
    50% {opacity: 0;}
    100%{opacity: 0;}
}

@keyframes disappear-custom-alert {
    0% {opacity: 1;}
    50% {opacity: 0;}
    100%{opacity: 0;}
}

/* BACKGROUND APPEAR/DISAPPEAR */

#alert-background.appear-custom-alert {
    -webkit-animation: appear-background 0.5s;
    animation: appear-background 0.5s;
}

#alert-background.disappear-custom-alert {
    -webkit-animation: disappear-background 1s;
    animation: disappear-background 1s;
}

@-webkit-keyframes appear-background {
    0% {opacity: 0; visibility: visible}
    50% {opacity: var(--alertOverlayOpacity);}
}

@keyframes appear-background {
    0% {opacity: 0; visibility: visible}
    50% {opacity: var(--alertOverlayOpacity);}
}

@-webkit-keyframes disappear-background {
    0% {opacity: var(--alertOverlayOpacity); visibility: visible}
    40% {opacity: var(--alertOverlayOpacity); visibility: visible}
    100% {opacity: 0; visibility: hidden}
}

@keyframes disappear-background {
    0% {opacity: var(--alertOverlayOpacity); visibility: visible}
    40% {opacity: var(--alertOverlayOpacity); visibility: visible}
    100% {opacity: 0; visibility: hidden}
}
