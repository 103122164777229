@import "../variables.css";

#player-container {
    float: left;
    display: inline;
    margin: 5px 10px 5px 5px;
}

#voting-card-container {
    display: flex;
    width: 100%;
    align-content: center;
}

#voting-card {
    width: 30vmin;
    border-radius: 8%/11%;
    margin: 10px auto;
}