@import '../variables.css';

#policy-enacted-container {
    display: flex;
    width: 20vmin;
    height: 26vmin;
    margin: auto auto;
    position: relative;
    perspective: 1000px;
}

#policy-enacted-cover-front, #policy-enacted-cover-back {
    position: absolute;
    width: auto;
    height: 26vmin;
    z-index: 54;
    transition-timing-function: linear;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; /* Safari */
    -moz-backface-visibility: hidden;
    left: -99.5%;
    top: 0;
    transition: 1s transform ease, 0.5s left ease-in-out;
}

#policy-enacted-cover-front.show-policy-shift {
    left: -49.5%;
}


#policy-enacted-cover-front.show-policy-flip {
    transform: rotateY(-180deg);
    z-index: 53;
}

#policy-enacted-cover-back {
    transform: rotateY(180deg);
}

#policy-enacted-cover-back.show-policy-shift {
    left: -49.5%;
}

#policy-enacted-cover-back.show-policy-flip {
    transform: rotateY(0deg);
}

#policy-enacted-back {
    position: absolute;
    width: auto;
    height: 26vmin;
    z-index: 51;
    left: -100%;
    transition: 0.5s left;
    transition-timing-function: ease-in-out;
}

#policy-enacted-back.show-policy-shift{
    left: -50%;
}

#policy-enacted-policy {
    position: absolute;
    width: auto;
    height: 100%;
    left: -7.5%;
    z-index: 52;
    transition: 0.5s left;
    transition-timing-function: ease-in-out;
}

#policy-enacted-policy.show-policy-shift{
    left: 42.5%;
}