@import '../variables.css';

#player-container {
    position:relative;
    margin: 5px;
    border-radius: 16%/10%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

#player-container.darken {
    filter: none;
}

#player-container.highlight {
    background-color: var(--selectionHover);
}

#player-icon {
    position: absolute;
    width: 82%;
    left: 9%;
    top: 6.3%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#player-icon.darken {
    filter: brightness(calc(var(--playerBrightnessFilter) - 5%));
}

#player-image {
    width: 20vmin;
    max-width: 16vh;
    height: auto;
    image-rendering: crisp-edges;
    display: block;
}

#player-image.darken {
    filter: brightness(calc(var(--playerBrightnessFilter) - 5%));
}

#player-name {
    position:absolute;
    color: var(--backgroundDark);
    font-family: var(--textFont);
    top: 64%;
    left: 10%;
    width: 80%;
    height: 16%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

#player-name.darken {
    color: var(--textShadowed);
}

#player-identity-icon {
    position:absolute;
    top: 75%;
    left: 8%;
    width: 28%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

#player-busy-icon {
    position: absolute;
    top: 43%;
    left: 50%;
    width: 37.5%;
}

#player-icon-vote {
    position: absolute;
    width: 73%;
    image-rendering: crisp-edges;
    top: 43%;
    left: 14%;
    transition: 0.5s visibility;
}

#player-identity-label {
    position:absolute;
    top: 69%;
    left: 36%;
    width: 50%;
    height: 12%;
    /*noinspection CssInvalidFunction*/
    font-size: min(2.3vh, 3vmin);
    color: var(--textColorHighlight);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: var(--textFont);
}

#player-identity-label.liberal-text {
    color: var(--textColorLiberal);
}

#player-disabled-label {
    position:absolute;
    top: 0;
    left: 0;
    font-family: var(--headerFont);
    color: var(--playerDisabledTextColor);
    padding: 15% 0;
    height: 100%;
    width: 100%;
    font-size: calc(4vmin - 0.3vw);
    font-weight: normal;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.darken {
    filter: brightness(var(--playerBrightnessFilter));
}


/* Icon Animations */
.player-icon-default {
    visibility: hidden;
}

.player-icon-show {
    visibility: visible;
    animation: player-icon-show 0.5s;
}

.player-icon-hide {
    visibility: hidden;
    animation: player-icon-hide 0.5s;
}

@-webkit-keyframes player-icon-show {
    0% {top: 35%; opacity: 0; visibility: visible}
    100% {top: 43%; opacity: 1}
}

@keyframes player-icon-show {
    0% {top: 35%; opacity: 0; visibility: visible}
    100% {top: 43%; opacity: 1}
}

@-webkit-keyframes player-icon-hide {
    0% {top: 43%; opacity: 1; visibility: visible}
    100% {top:43%; opacity: 0; visibility: hidden}
}

@keyframes player-icon-hide {
    0% {top: 43%; opacity: 1; visibility: visible}
    100% {top:43%; opacity: 0; visibility: hidden}
}