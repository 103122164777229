@import './variables.css';

.selectable {
    background-color: var(--selectionNone);
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.selectable.selected {
    background-color: var(--selectionPressed);
}

.selectable:hover {
    background-color: var(--selectionHover);
}

.selectable:active {
    background-color: var(--selectionPressed);
}

/* Handle disabled selectable objects-- disable highlighting. */
.selectable.disabled {
    background-color: var(--selectionNone);
}

.selectable.disabled:hover {
    background-color: var(--selectionNone);
}

.selectable.disabled:active {
    background-color: var(--selectionNone);
}